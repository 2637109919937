import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "schedulePeriod",
    "alternativeText",
    "modalDialog",
    "duplicateScheduleCheckbox",
    "planPlanTableType",
    "tableTeplo",
    "table",
    "myTable",
    "template"
  ];

  connect() {
    this.modal = new bootstrap.Modal(this.element, {})
    this.modal.show()

    this.duplicateScheduleCheckboxTarget.addEventListener("change", this.toggleSchedule.bind(this))
    this.planPlanTableTypeTarget.addEventListener("change", this.toggleTable.bind(this))
  }

  hideBeforeRender(event) {
    if (this.isOpen()) {
      event.preventDefault()
      this.element.addEventListener('hidden.bs.modal', event.detail.resume)
      this.modal.hide()
    }
  }

  isOpen() {
    return this.element.classList.contains("show")
  }

  toggleSchedule() {
    const isChecked = this.duplicateScheduleCheckboxTarget.checked;

    if (isChecked) {
      this.schedulePeriodTarget.classList.remove("d-none");
      this.alternativeTextTarget.classList.add("d-none");
      this.modalDialogTarget.classList.remove("w-table");
    } else {
      this.schedulePeriodTarget.classList.add("d-none");
      this.alternativeTextTarget.classList.remove("d-none");
      this.modalDialogTarget.classList.add("w-table");
    }
  }

  toggleTable() {
    const selectedValue = this.planPlanTableTypeTarget.value;

    if (selectedValue === 'heat') {
      this.tableTeploTarget.style.display = '';
      this.tableTarget.style.display = 'none';
    } else {
      this.tableTeploTarget.style.display = 'none';
      this.tableTarget.style.display = '';
    }
  }

  addRow(event) {
    event.preventDefault();
    const template = this.templateTarget.content.cloneNode(true);
    this.myTableTarget.appendChild(template);
  }

  removeRow(event) {
    event.preventDefault();
    const row = event.target.closest("tr");
    if (row) {
      row.remove();
    }
  }
}
