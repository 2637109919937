import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'

// import 'jquery'
import * as bootstrap from 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import 'select2/dist/js/select2.js'
import 'select2/dist/css/select2.min.css'
import 'stylesheets/crm.scss'
// import '../packs/masked_input.js'
import '../packs/maskedinput.js'
// import '../packs/sortable.js'
import '../packs/upload_files.js'
// tinymce
import '../packs/tinymce/tinymce.js'
import '../packs/tinymce/theme.js'
import '../packs/tinymce/icons.js'
import '../packs/tinymce/plugins/lineheight/plugin.js'
import '../packs/tinymce/plugins/code/plugin.min.js'

import '../packs/tinymce/skin.min.css'
import '../packs/tinymce/content.min.css'
import '../packs/tinymce/content.css'

import 'flatpickr'
// import 'stylesheets/orders.scss'
import 'flatpickr/dist/flatpickr.min.css'

import '@hotwired/turbo-rails'
import 'controllers'

// require("tinymce")
// import 'tinymce'
// require("tinymce")
require("jquery")
require("@nathanvda/cocoon")
require('toastr')

const images = require.context('../images', true)
const $ = require('jquery')

window.bootstrap = bootstrap

global.toastr = require('toastr')
toastr.options = {
  closeButton: true,
  positionClass: 'toast-top-center'
}

global.jQuery, global.$ = require("jquery");

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// $(document).on('select2:open', () => {
//     document.querySelector('.select2-search__field').focus();
// });


function flatpickrConnect() {
  const Ukranian = require("flatpickr/dist/l10n/uk.js").default.uk;

  const flatpickrOptions = {
    enableTime: false,
    minuteIncrement: 5,
    time_24hr: true,
    format: "d.m.Y",
    altFormat: "d.m.Y",
    allowInput: true,
    altInput: true,
    locale: Ukranian
  }

  const flatpickrRangeOprions = {
    enableTime: false,
    minuteIncrement: 5,
    time_24hr: true,
    locale: Ukranian,
    format: "d.m.Y",
    altFormat: "d.m.Y",
    allowInput: true,
    altInput: true,
    mode: 'range'
  }

  flatpickr('.flatpickr', flatpickrOptions);
  flatpickr('.flatpickr-range', flatpickrRangeOprions);  
};

document.addEventListener('turbolinks:load', () => {
  setTimeout(function(){
    $('#flash').remove();
  }, 10000);

  $("[data-link]").click(function() {
    window.location = $(this).data("link");
  });

  //document.getElementById("meterLink").addEventListener("click", flatpickrConnect, false);    
})


document.addEventListener('turbolinks:load', () => {
  flatpickrConnect();
});

// document.addEventListener('turbolinks:load', () => {

//   $('.select2').on("click", function(e) {
//     $(".dropdown-menu").addClass("edit")
//   })

//   $(".select").on("change", function() {
//     $("div.dropdown").removeClass("open")
//   })

// })

document.addEventListener('turbolinks:load', () => {
  const initializeMask = (element) => {
    $(element).mask('+380 (99) 999-99-99', {
      completed: () => {
        element.setCustomValidity('');
      }
    });
  };

  initializeMask('#order_tel');
  $('.order_phone').each((index, element) => {
    initializeMask(element);
  })

  $('#q_service_provider_id_eq').select2({
    placeholder: 'Виконавець послуг'
  });

  $('#q_district_id_eq').select2({
    placeholder: 'Район'
  });

  $('#q_order_item_service_type_id_eq').select2({
    placeholder: 'Тип Послуги'
  });

  $('#q_order_item_service_id_eq').select2({
    placeholder: 'Послуга'
  });
})

document.addEventListener('turbolinks:load', () => {
  $("#q_tel_cont").mask("+380 (99) 999-99-99");
})

document.addEventListener('turbolinks:load', () => {
  searchSelectAddress();
})

document.addEventListener('turbolinks:load', () => {
  selectAddress();
})

document.addEventListener('turbolinks:load', () => {
  crmOrderEditItemsCount();
})

document.addEventListener('turbolinks:load', () => {
  crmNewAddress();
})

const crmNewAddress = function (){
  const api_url = window.location.origin;

  let cityOptions = []
  let streetOptions = []
  let city_id = ''
  let street_id = ''

  $('#address_city_name').select2({
    tags: true,
    ajax: {
      url: `${api_url}/api/city_names`,
      data: (params) => {
        return {
          q: {
            title_cont: params.term
          }
        };
      },
      processResults: (data, params) => {
        cityOptions = data;
        return {
          results: $.map(data, (item) => {
              return {
                  text: item.title,
                  id: item.id
              }
          })
        }
      },
    }
  });

  $('#address_city_name').on('select2:select', (e) => {
    city_id = cityOptions.find(opt => opt.id == e.target.value).id
  });

  $('#address_street_name').select2({
    tags: true,
    ajax: {
      url: `${api_url}/api/street_names`,
      data: (params) => {
        return {
          q: {
            street_name_cont: params.term,
            city_id_eq: city_id
          }
        };
      },
      processResults: (data, params) => {
        streetOptions = data;
        return {
          results: $.map(data, (item) => {
              return {
                  text: item.street_name,
                  id: item.id
              }
          })
        }
      },
    }
  });

  $('#address_street').on('select2:select', (e) => {
    street_id = streetOptions.find(opt => opt.id == e.target.value).id
  });

  $('#address_district_name').select2({
    tags: true,
    ajax: {
      url: `${api_url}/api/district_names`,
      data: (params) => {
        return {
          q: {
            title_cont: params.term,
            city_id_eq: city_id
          }
        };
      },
      processResults: (data, params) => {
        streetOptions = data;
        return {
          results: $.map(data, (item) => {
              return {
                  text: item.title,
                  id: item.id
              }
          })
        }
      },
    }
  });
}

const crmOrderEditItemsCount = () => {
  Array.from(document.getElementsByClassName('collapse-1')).map(el => el).forEach(el => {
    var myCollapsible = el
    myCollapsible.addEventListener('hidden.bs.collapse', function () {
      $(this).parent().parent().children().find(".main-category-collapse").find(".bi-1").removeClass("bi-dash-lg").addClass("bi-plus-lg");
    })

    myCollapsible.addEventListener('shown.bs.collapse', function () {
      $(this).parent().parent().children().find(".main-category-collapse").find(".bi-1").removeClass("bi-plus-lg").addClass("bi-dash-lg");
    })
  })

  Array.from(document.getElementsByClassName('collapse-2')).map(el => el).forEach((el, index) => {
    var myCollapsible = el

    myCollapsible.addEventListener('hidden.bs.collapse', function () {
      $(this).parent().parent().children().find(`.bi-${index}`).removeClass("bi-dash-lg").addClass("bi-plus-lg");
    })

    myCollapsible.addEventListener('shown.bs.collapse', function () {
      $(this).parent().parent().children().find(`.bi-${index}`).removeClass("bi-plus-lg").addClass("bi-dash-lg");
    })
  })
}

const searchSelectAddress = function (){
  const api_url = window.location.origin;

  let streetOptions = []
  let houseOptions = []

  let street_id = ''
  let house_id = ''
  let flat_numbers = ''

  $('#q_street_id_eq').select2({
    placeholder: 'Вулиця',
    ajax: {
      url: `${api_url}/api/street_names`,
      data: (params) => {
        return {
          q: {
            street_name_cont: params.term,
            city_id_eq: 1
          }
        };
      },
      processResults: (data, params) => {
        streetOptions = data;
        $(".dropdown-menu-address").addClass("show")
        return {
          results: $.map(data, (item) => {
              return {
                  text: item.street_name,
                  id: item.id
              }
          })
        }
      },
    }
  });

  $('#q_street_id_eq').on('select2:select', (e) => {
    street_id = streetOptions.find(opt => opt.id == e.target.value).id
  });

  $('#q_house_id_eq').select2({
    placeholder: 'Будинок',
    ajax: {
      url: `${api_url}/api/house_numbers`,
      data: (params) => {
        return {
          q: {
            street_id_eq: street_id,
            number_cont: params.term
          }
        };
      },
      processResults: (data, params) => {
        houseOptions = data;
        return {
          results: $.map(data, (item) => {
              return {
                text: item.number,
                id: item.id
              }
          })
        }
      },
    }
  });


  $('#q_house_id_eq').on('select2:select', (e) => {
    const selected_option = houseOptions.find(opt => opt.id == e.target.value);
    house_id = selected_option.id || ''
  });

  $('#q_flat_id_eq').select2({
    placeholder: 'Квартира',
    ajax: {
      url: `${api_url}/api/flat_numbers`,
      data: (params) => {
        return {
          q: {
            id_eq: house_id,
            number_eq: params.term
          }
        };
      },
      processResults: (data, params) => {
        return {
          results: $.map(data, (item) => {
            return {
              text: item,
              id: item
            }
          })
        }
      },
    }
  });
}

// order_order_items_attributes_0_count
const selectAddress = function (){

  try {
    Array.from(document.querySelectorAll("[id^='order-item-price']")).map(el => el).forEach(el => {
      const serviceId = +el.getAttribute('id').match(/\d+$/)[0]
      console.log({
        itemElement: el,
        serviceId: serviceId
      })
      
      if (serviceId == null) { 
        return
      } 

      document.getElementById(`order-item-count-${serviceId}`)
              .addEventListener('input', (ev) => {
                const price = document.getElementById(`order-item-price-${serviceId}`).innerText
                const total = Number(price) * ev.target.value
                document.getElementById(`order-item-total-${serviceId}`).value = total
                const totalAmount = Array.from(document.querySelectorAll("[id^='order-item-total']"))
                                          .map(el => +el.value ).reduce((a, b) => a + b, 0);
                let total_input = document.getElementById('order_total');

                total_input.value = +totalAmount

                if (+total_input.value >= 0.01) {
                  total_input.setCustomValidity('');
                } else {
                  total_input.setCustomValidity('Оберіть послугу');
                }
              })
    })
  } catch (e) {
    console.log(e); // Logs the error
  }

  const api_url = window.location.origin;

  let cityOptions = []
  let streetOptions = []
  let houseOptions = []

  let city_id = 1
  let street_id = ''
  let house_id = ''
  let flat_numbers = ''
  let flat_id = '' 
  let street_name = ''
  let house_number = ''

  if (window.location.search.length > 1) {
    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();
    const params = paramsToObject(entries);

    street_name = params['new_attrs[street_name]'] || ''
    house_number = params['new_attrs[house_number]'] || ''
    city_id = params['new_attrs[city_id]'] || ''
    street_id  = params['new_attrs[street_id]'] || ''
    house_id = params['new_attrs[house_id]'] || ''
    flat_id = params['new_attrs[flat_id]'] || ''

    $('#order_street_id').select2({
      data: [{id: street_id, text: street_name}]
    })

    $('#order_house_id').select2({
      data: [{id: house_id, text: house_number}]
    })
    $('#order_house_id').val(house_id)

    $('#order_flat_id').select2({
      data: [{id: flat_id, text: flat_id}]
    })
    $('#order_flat_id').val(flat_id)
  }


  $('#order_city_id').select2({
    data: [{id: 1, text: 'Київ'}]
  })

  $("#order_city_id").attr('disabled', true);
  $("#order_city_id").val(1).trigger('change');
  $('#order_city_id').on('select2:select', (e) => {
    city_id = cityOptions.find(opt => opt.id == e.target.value).id
  });

  $('#order_street_id').select2({
    ajax: {
      url: `${api_url}/api/street_names`,
      data: (params) => {
        return {
          q: {
            street_name_cont: params.term,
            city_id_eq: city_id
          }
        };
      },
      processResults: (data, params) => {
        streetOptions = data;
        return {
          results: $.map(data, (item) => {
              return {
                  text: item.street_name,
                  id: item.id
              }
          })
        }
      },
    }
  });

  $('#order_street_id').on('select2:select', (e) => {
    street_id = streetOptions.find(opt => opt.id == e.target.value)?.id
  });

  $('#order_house_id').select2({
    ajax: {
      url: `${api_url}/api/house_numbers`,
      data: (params) => {
        return {
          q: {
            street_id_eq: street_id,
            number_cont: params.term
          }
        };
      },
      processResults: (data, params) => {
        houseOptions = data;
        return {
          results: $.map(data, (item) => {
              return {
                text: item.number,
                id: item.id
              }
          })
        }
      },
    }
  });

  $('#order_house_id').on('select2:select', (e) => {
    const selected_option = houseOptions.find(opt => opt.id == e.target.value);

    let district_input = document.getElementById('order_district_id')
    district_input.value = selected_option?.district_id
    // console.log('order_house_id', document.getElementById('order_district_id'))

    let service_provider_gvp_input = document.getElementById('order_service_provider_gvp')
    service_provider_gvp_input.value = selected_option?.service_provider_gvp

    let service_provider_hvp_input = document.getElementById('order_service_provider_hvp')
    service_provider_hvp_input.value = selected_option?.service_provider_hvp

    let service_provider_co_input = document.getElementById('order_service_provider_co')
    service_provider_co_input.value = selected_option?.service_provider_co

    house_id = selected_option?.id || e.target.value || ''
  });

  $('#order_flat_id').select2({
    ajax: {
      url: `${api_url}/api/flat_numbers`,
      data: (params) => {
        return {
          q: {
            id_eq: house_id,
            number_eq: params.term
          }
        };
      },
      processResults: (data, params) => {
        return {
          results: $.map(data, (item) => {
            return {
              text: item,
              id: item
            }
          })
        }
      },
    }
  });
  
  try {
    document.getElementById('order_total').setCustomValidity('Оберіть послугу');
  } catch (e) {
    console.log(e); // Logs the error
  }
}

$(document).ready(function () {
  function numberingHeaders(query) {
    $('.headers').on('click', function (e) {
      $(document).ready(function () {
        let headers = e.target.closest('.nested-fields').querySelectorAll(query),
            header  = headers[headers.length - 1];

        if (header) {
          header.textContent = header.textContent.replace(/.$/, headers.length);
        };

        flatpickrConnect();
      });
    });
  };

  function penaltyHeaders() {
    $('.add-penalty-button').on('click', function (e) {
      $(document).ready(function () {
        e.target.closest('.nested-fields').querySelector('.penalty-header').style.display = 'block';

        removePenaltyHeader();
      });
    });
  }

  function removePenaltyHeader() {
    $('.remove_penalty').on('click', function(e) {
      const penaltiesContainer = e.target.closest('.penalties-container'),
        elements = penaltiesContainer.querySelectorAll('.penalties-fields'),
        visibleElements = Array.from(elements).filter(element => window.getComputedStyle(element).display === 'block');

      if (visibleElements.length <= 1) {
        penaltiesContainer.querySelector('.penalty-header').style.display = 'none';
      };
    });
  };

  $('.add-penalty-principal').on('click', function () {
    $('#penaltyTitleSection').show();
  })

  function initializeContract() {
    numberingHeaders('.contract-condition h3');
    numberingHeaders('.addition-agreement h3');
    removePenaltyHeader();
    penaltyHeaders();
    attachEventListeners();
  }

  initializeContract()

  $('#add-new-contract').on('click', function (e) {
    $(document).ready(function () {
      initializeContract()
      flatpickrConnect();
      populateFieldsAgent();

      $('.contract-nested-fields .contract-header').last().text('Договір ' + $('.contract-nested-fields').length);

      $('.add-contract-condition').on('click', function (e) {
        $(document).ready(function () {
          penaltyHeaders();
          populateFieldsAgent();
          copyOptionsFromFirstToLast(e
          );
        });
      });
    });
  });

  $('.add-contract-condition').on('click', function (e) {
    $(document).ready(function () {
      populateFieldsAgent();
      copyOptionsFromFirstToLast(e);
    });
  });

  function setCompanyType() {
    $('#principal_company_type').on('change', function (e) {
      switch(event.target.value) {
        case 'principal':
          $('#add-penalty').show();
          $('#add-contract').show();

          let selected = $('#principal_agent_b_district_ids').val().split(' ');
          $('#principal_district_ids').val(selected).trigger('change');
          $('#principal_district_ids').attr('disabled', true);
          break
        case 'agent_a':
        case 'agent_b':
          $('#add-penalty').show();
          $('#add-contract').hide();
          $('#principal_district_ids').removeAttr('disabled');
          break
        default:
          $('#add-penalty').hide();
          $('#add-contract').hide();
          $('#principal_district_ids').val(null).trigger('change');
          $('#principal_district_ids').attr('disabled', true);
      }
    });
  }

  setCompanyType();

  function setServiceKind() {
    function toggleVisibility(meterBlock, softserve, bitek, evrorec) {
      meterBlock.find('.softserve').toggle(softserve);
      meterBlock.find('.bitek').toggle(bitek);
      meterBlock.find('.evrorec').toggle(evrorec);
    }

    function updateVisibility(element) {
      let meterBlock = $(element).closest('.meter_form');
      let serviceKind = $(element).val();

      switch(serviceKind) {
        case 'hot_water':
          toggleVisibility(meterBlock, true, true, true);
          break;
        case 'cold_water':
          toggleVisibility(meterBlock, true, false, false);
          break;
        case 'heating':
          toggleVisibility(meterBlock, false, true, true);
          break;
        default:
          toggleVisibility(meterBlock, true, true, true);
      }
    }

    $('.meter_service_kind').on('change', function (event) {
      updateVisibility(event.target);
    });

    $('.meter_service_kind').each(function() {
      updateVisibility(this);
    });
  }

  setServiceKind();

  function populateFieldsAgent() {
    $('.populate_fields_agent').change(function () {
      let agentFields = event.target.closest('.agent-fields')

      if (event.target.value.length) {
        $.ajax({
          url: '/crm/settings/principals/load_agent',
          type: 'get',
          data: {'id': event.target.value},
          dataType: 'json',
          success: function (data) {
            let agent = data;
            agentFields.querySelector('#agent-phone').value = agent.tel;
            agentFields.querySelector('#agent-email').value = agent.email;
          }
        });
      } else {
        agentFields.querySelector('#agent-phone').value = '';
        agentFields.querySelector('#agent-email').value = '';
      }
    });
  };

  populateFieldsAgent();

  $('.collapse-header').on('click', function() {
    $(this).parent().find('i').toggleClass('bi-chevron-down bi-chevron-up');
  });

  function fetchAgentsData(url, params, successCallback) {
    $.ajax({
      url: url,
      type: 'get',
      data: { ...params },
      dataType: 'json',
      success: successCallback,
      error: function(xhr, status, error) {
        console.error('Error fetching agents data:', error);
      }
    });
  };

  function attachEventListeners() {
    $('.agent_a_select').on('change', function(event) {
      selectAgents(event, '.agent_a_id', '.agent_a_fields');
    });

    $('.agent_b_select').on('change', function(event) {
      selectAgents(event, '.agent_b_id', '.agent_b_fields');
    });
  }

  function selectAgents(event, agent_type, agent_fields, clean = true) {
    let target = event.target || event,
        agent = (agent_type === '.agent_a_id') ? 'agent_a' : 'agent_b',
        params = { agent_type: agent, agent_id: target.value };

    fetchAgentsData('/crm/settings/principals/load_agents_data', params, function(data) {
      let agents       = data.agents,
          parent       = target.closest('.contract-nested-fields'),
          selectFields = parent.querySelectorAll(agent_type);

      selectFields.forEach(function(select, index) {
        if (index !== (selectFields.length - 1) && clean === false) return;
        select.innerHTML = '';
      });

      if (clean) {
        parent.querySelectorAll(agent_fields + ' #agent-email').forEach((select) => select.value = '');
        parent.querySelectorAll(agent_fields + ' #agent-phone').forEach((select) => select.value = '');
      }

      selectFields.forEach(function(select, index) {
        if (index !== (selectFields.length - 1) && clean === false) return;

        let opt = document.createElement('option');
        opt.value = '';
        opt.innerHTML = '';
        select.appendChild(opt);

        agents.forEach(function(element) {
          let opt = document.createElement('option');
          opt.value = element.id;
          opt.innerHTML = element.first_name;
          select.appendChild(opt);
        });
      });

      if (agent_type === '.agent_b_id' && event.target !== null) {
        let districtsFields = parent.querySelectorAll('.district_ids');
        districtsFields.forEach((district) => district.innerHTML = '');

        if (event.target.value == '') {
          return;
        };

        $.ajax({
          url: '/crm/settings/principals/load_districts',
          type: 'get',
          data: { 'id': target.value },
          dataType: 'script',
          success: function(data) {
            let options = document.createDocumentFragment();
            JSON.parse(data).forEach(function(district) {
              let opt = document.createElement('option');
              opt.value = district.id;
              opt.innerHTML = district.title;
              options.appendChild(opt);
            });

            districtsFields.forEach((district) => district.appendChild(options.cloneNode(true)));
          },
          error: function(xhr, status, error) {
            console.error('Error loading districts:', error);
          }
        });
      }
    });
  };

  function copyOptionsFromFirstToLast(e) {
    let contract                        = e.target.closest('.nested-fields'),
        conditions                      = Array.from(contract.querySelectorAll('.contract-condition')),
        [firstCondition, lastCondition] = [conditions[0], conditions[conditions.length - 1]];

    let copyOptions = (sourceSelect, targetSelect) => {
      let options = Array.from(sourceSelect.options);
      targetSelect.innerHTML = '';
      options.forEach((option) => {
        let newOption = new Option(option.text, option.value);
        targetSelect.appendChild(newOption);
      });
    };

    copyOptions(firstCondition.querySelector('.district_ids'), lastCondition.querySelector('.district_ids'));
    copyOptions(firstCondition.querySelector('.agent_a_id'), lastCondition.querySelector('.agent_a_id'));
    copyOptions(firstCondition.querySelector('.agent_b_id'), lastCondition.querySelector('.agent_b_id'));
    copyOptions(firstCondition.querySelector('.agent_c_id'), lastCondition.querySelector('.agent_c_id'));
  };

  let principalRequestSent = false;
  $('#principal_status_dropdown').on('change', function() {
    if ($(this).val() === 'principal' && !principalRequestSent) {
      principalRequestSent = true;

      $.ajax({
        url: '/crm/settings/principals/new',
        type: 'GET',
        dataType: 'script',
        success: function(response) {
          $('#principal-general-penalties').html(response);
        }
      });
    }
  });
});

function paramsToObject(entries) {
  const result = {}
  for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}
