import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="plan"
export default class extends Controller {
  static targets = ["date", "types"];

  fetchTypes(event) {
    const selectedDate = event.target.value;
    const url = event.target.dataset.url;

    if (!selectedDate) return;

    fetch(`${url}?q[plan_date]=${selectedDate}`, {
      headers: { Accept: "application/json" },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        this.updateTypes(data.types);
      })
      .catch((error) => console.error("Error fetching plan types:", error));
  }

  updateTypes(types) {
    const typesSelect = document.getElementById("q_plan_table_type_eq");
    typesSelect.innerHTML = types
      .map(([label, value]) => `<option value="${value}">${label}</option>`)
      .join("");
  }
}
